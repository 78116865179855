import config from "@/config"
import { foxentryPhoneValidation } from "@/services"
import { debounce } from "lodash"

export const contactForms = {
  init() {

    console.log('contactForms init');

    const contactForms = document.querySelectorAll('.wpcf7-form');
    if (!contactForms.length) return;

    contactForms.forEach(contactForm => {
      const submitButton = contactForm.querySelector('button[type="submit"]')
      const myGroups = contactForm.querySelectorAll('.b-form__group:not(.b-form__group .b-form__group)');

      const responseOutput = contactForm.querySelector('.wpcf7-response-output')
      responseOutput?.classList?.add('d-none')

      contactForm.addEventListener('submit', () => {
        setSubmitButton({ submitButton, loading: true, disabled: true })
      })

      contactForm.addEventListener('wpcf7spam', () => {
        setSubmitButton({ submitButton, loading: false, disabled: true })
      })

      contactForm.addEventListener('wpcf7mailfailed', () => {
        setSubmitButton({ submitButton, loading: false, disabled: false })
      })

      contactForm.addEventListener('wpcf7invalid', event => {
        const invalidFields = event?.detail?.apiResponse?.invalid_fields

        setSubmitButton({ submitButton, loading: false, disabled: false })

        if(!invalidFields) {
          return
        }

        myGroups.forEach(group => {
          const input = group.querySelector('input, textarea')
          const helperText = group.querySelector('.b-form__helper-text');

          const maybeInvalidField = invalidFields.find(({ field }) => field === input.getAttribute('name'))
          const isInvalid = !!maybeInvalidField

          if(isInvalid) {
            group.classList.add('b-form__group--error');
            if(helperText) {
              helperText.classList.add('d-block');
              helperText.textContent = maybeInvalidField?.message
            }
          } else {
            group.classList.remove('b-form__group--error');
            helperText?.classList.remove('d-block');
          }
        })
      })

      contactForm.addEventListener('wpcf7mailsent', _event => {

        setSubmitButton({ submitButton, loading: false, disabled: false })

        myGroups.forEach(group => {
          const helperText = group.querySelector('.b-form__helper-text')
          group.classList.remove('b-form__group--filled');
          group.classList.remove('b-form__group--error');
          helperText.classList.remove('d-block');
        })
      }, false);

      myGroups.forEach(group => {
        const phoneSelect = group.querySelector('.b-form-select--phone')

        const isPhoneInput = !!phoneSelect

        const input = group.querySelector('input, textarea');
        const hiddenInput = group.querySelector('input[type="hidden"]')
        const select = isPhoneInput ? phoneSelect.querySelector('select') : null
        const helperText = group.querySelector('.b-form__helper-text');

        if (!input || isPhoneInput && !select && !hiddenInput) {
          return
        }

        if(isPhoneInput && select && hiddenInput) {
          let prefix = config.phonePrefix
          let number

          const validatePhoneDebounced = debounce(validatePhone, 300)

          select.addEventListener('change', async (event) => {
            prefix = event.target.value
            hiddenInput.value = `${prefix ?? ''} ${number ?? ''}`
            validatePhoneDebounced({ prefix, number, group, helperText, submitButton })
          })
          
          input.addEventListener('input', async (event) => {
            number = event.target.value
            hiddenInput.value = `${prefix ?? ''} ${number ?? ''}`
            validatePhoneDebounced({ prefix, number, group, helperText, submitButton })
          })
        }


        input.addEventListener('input', (event) => {
          if (event.target.value.trim() !== '') {
            group.classList.add('b-form__group--filled');
          } else {
            group.classList.remove('b-form__group--filled');
          }
        });

        input.addEventListener('focus', () => {
          group.classList.add('b-form__group--focused');
        });

        input.addEventListener('blur', () => {
          group.classList.remove('b-form__group--focused');
        });
      });
    });

    'use strict';
    (function (document, window, index) {
      var inputs = document.querySelectorAll('.wpcf7-file');
      Array.prototype.forEach.call(inputs, function(input) {
        var label = input.parentElement.nextElementSibling,
          labelVal = label.innerHTML;

        input.addEventListener('change', function(e) {
          var fileName = '';
          if (this.files && this.files.length > 1)
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
          else
            fileName = e.target.value.split('\\').pop();

          if (fileName)
            label.querySelector('span').innerHTML = fileName;
          else
            label.innerHTML = labelVal;
        });

        // Firefox bug fix
        input.addEventListener('focus', function() {
          input.classList.add('has-focus');
        });
        input.addEventListener('blur', function() {
          input.classList.remove('has-focus');
        });
      });
    }(document, window, 0));
  },
}

const setSubmitButton = ({ submitButton, loading, disabled }) => {
  if(!submitButton) {
    return
  }

  if(loading) {
    submitButton.classList.add('b-button--loading')
  } else {
    submitButton.classList.remove('b-button--loading')
  }

  submitButton.disabled = Boolean(disabled)
}

const validatePhone = async ({ prefix, number, group, helperText, submitButton }) => {
  if (prefix && number) {
    setSubmitButton({ submitButton, loading: true, disabled: true })
    const { data } = await foxentryPhoneValidation(
      { prefix: prefix, number: number },
      { validationType: 'extended', formatNumber: false }
    );

    if (!data.response.result.isValid) {
      setSubmitButton({ submitButton, loading: false, disabled: true })
      group.classList.add('b-form__group--error');
      if (helperText) {
        helperText.classList.add('d-block');
        helperText.textContent = 'Neplatné telefonní číslo';
      }
    } else {
      setSubmitButton({ submitButton, loading: false, disabled: false })
      group.classList.remove('b-form__group--error');
      helperText?.classList.remove('d-block');
    }
  }
};

