<script setup>
import { ref, inject, computed } from 'vue'
import { useDasStore } from '@/stores/das.store';
import { useCheckoutStore } from '@/stores/checkout.store'
import { useGlobalStore } from '@/stores/global.store'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'
import globalConfig from '@/config'
import NxtInput from '@/components/shared/NxtInput.vue';
import Button from '@/stories/Button.vue'

const componentId = inject('componentId')

const dasStore = useDasStore();
const $checkout = useCheckoutStore()
const $global = useGlobalStore();

const isPending = ref(false)


const transferPrice = computed(() => {
  const dasTransfer = dasStore.getDas(componentId).transfer
  const tldObject = dasStore.getTldObject(dasTransfer?.toplevel, componentId)
  let price = tldObject.renew_customer_price
  /* if (tldObject.renew_customer_price > 0) {
    price = tldObject.reg_customer_price
  } */
  return price
})

const form = ref({
  authId: '',
});

const rules = ref({
  authId: { required: required },
})

let v$ = useVuelidate(rules, form);

const currentDas = computed(() => dasStore.getDas(componentId))

const submit = async () => {
  const isValid = await v$.value.$validate()
  if(!isValid) {
    return
  }

  try {
    if(dasStore.isCdas(componentId)) {
      $checkout.addingToBasket = true
      addToCart()
      $checkout.setStep(1)
    } else {
      isPending.value = true
      await addToCart()
    }
    dasStore.switchTransfer(componentId)
  } catch (error) {
    console.log(error);
  } finally {
    isPending.value = false
  }
}

async function addToCart() {
  return $checkout.addToCart({
      item_name: `${currentDas.value.transfer.domain_name}.${currentDas.value.transfer.toplevel}`,
      item_pack: dasStore.isCdas(componentId) ? currentDas.value.config.productPack : 'registration',
      item_period: dasStore.isCdas(componentId) ? currentDas.value.config.productPeriod : '12',
      item_count: dasStore.isCdas(componentId) ? currentDas.value.config.productCount : undefined,
      item_type: dasStore.isCdas(componentId) ? currentDas.value.config.productType : 'transfer',
      additional_item: dasStore.isCdas(componentId) ? 'domain-transfer' : undefined,
      [dasStore.isCdas(componentId) ? 'additional_item_auth_code' : 'item_auth_code']: form.value.authId
    })
}
</script>

<template>
  <div class="b-das__transfer">
    <div class="b-das__transfer-title">{{ $t('transfer.title') }}</div>
    <p class="b-das__transfer-description" v-html="$t('transfer.desc1', { price: transferPrice, currency: $global.getConfig('general').currency_code })"></p>
    <!-- <p class="b-das__transfer-description d-none d-md-block" v-html="$t('transfer.desc2')"></p> -->
    <form @submit.prevent="submit" class="form">
      <NxtInput
        v-model="form.authId"
        type="text"
        :label="$t('transfer.authInfoLabel')"
        class="b-form__input"
        :is-required="true"
        :v="v$.authId"
        :autofocus="true"
      />
      <div class="action">
        <Button
          type="submit"
          size="big-large-text"
          mobile-size="medium"
          :label="$t('transfer.submit')"
          class="w-100"
          :loading="isPending"
          :disabled="isPending"
        />
        <Button
          variant="link"
          size="big"
          :label="$t('transfer.help')"
          :href="$t('transfer.help_link')"
          target="_blank"
          class="p-0"
        />
      </div>
    </form>
  </div>
</template>
