<script setup>
import Slider from '@vueform/slider'

const props = defineProps(['modelValue', 'min', 'max', 'step', 'v', 'errorMsg', 'isDisabled'])
const emit = defineEmits(['onChange'])

const onChange = (value) => {
  emit('onChange', value)
}
</script>

<template>
  <div class="b-form-slider">
    <Slider 
      v-model="props.modelValue"
      id="server-config-param" 
      show-tooltip="drag" 
      tooltip-position="bottom"
      @slide="onChange"
      :min="props.min"
      :max="props.max"
      :step="props.step"
      :disabled="props.isDisabled"
    />
  </div>
</template>

<style src="@vueform/slider/themes/default.css">
</style>
