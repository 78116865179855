<script setup>
import Button from '@/stories/Button.vue';
import { ref, computed, onMounted, provide } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, minValue, maxValue } from '@/utils/i18n-validators';
import ServerConfigurationItem from './ServerConfigurationItem.vue';
import NxtChoices from '@/components/shared/NxtChoices.vue';
import ServerConfiguratorNameValidation from './ServerConfiguratorNameValidation.vue';
import ServerConfiguratorPriceItem from './ServerConfiguratorPriceItem.vue'
import { useCheckoutStore } from '@/stores/checkout.store';
import { parseOpts, openModal, closeAllModals } from '@/utils';
import config from '@/config'
import ServerConfiguratorTotalPriceItem from './ServerConfiguratorTotalPriceItem.vue'
import { getCustomRoundedNumber } from '@/utils/checkout'
import { useDataLayer } from '@/dataLayer';

const NVME_CUSTOM_MAX = 1024

const $checkout = useCheckoutStore()
const dataLayer = useDataLayer()

const props = defineProps({
  title: {
    type: String,
    default: "Nakonfigurujte svoje VPS",
  },
  defaultOS: {
    type: String,
    default: "WEBG_RRP_VPS_CENTRUM_WIN",
  },
  defaultCPU: {
    type: String,
    default: "1",
  },
  defaultRAM: {
    type: String,
    default: "1",
  },
  defaultNVME: {
    type: String,
    default: "1",
  },
});

const opts = ref(null);
const serverDefaultConfig = ref(null);
const step = ref(1);

const paramsListCollapse = ref(null)
const modalBody = ref(null)
const isSummaryModalOpen = ref(false)

const isAddingToCart = ref(false)
provide('isAddingToCart', isAddingToCart)

const params = ref({
  OS: props.defaultOS,
  CPU: props.defaultCPU,
  RAM: props.defaultRAM,
  NVME: props.defaultNVME,
});

const server = ref({
  ['product-name']: null,
  ['product-period']: null,
  ['product-type']: null,
});

const paramsRules = computed(() => {
  if (!serverDefaultConfig.value || serverDefaultConfig.value.step === 2) {
    return {};
  }
  return {
    CPU: {
      required,
      numeric,
      minValue: minValue(serverDefaultConfig?.value?.CPU?.minParamValue),
      maxValue: maxValue(serverDefaultConfig?.value.CPU?.maxParamValue),
    },
    RAM: {
      required,
      numeric,
      minValue: minValue(serverDefaultConfig?.value?.RAM?.minParamValue),
      maxValue: maxValue(serverDefaultConfig?.value.RAM?.maxParamValue),
    },
    NVME: {
      required,
      numeric,
      minValue: minValue(serverDefaultConfig?.value?.NVME?.minParamValue),
      maxValue: maxValue(NVME_CUSTOM_MAX),
    },
  };
});

const $vParams = useVuelidate(paramsRules, params);

const getTotalPriceForParam = (paramCount, formula) => {
  const getResult = new Function('V', `return ${formula}`);
  return getResult(paramCount);
};

const totalPriceParams = computed(() => ({
  CPU: getTotalPriceForParam(params.value.CPU, serverDefaultConfig?.value?.CPU?.paramPricePattern || 0),
  RAM: getTotalPriceForParam(params.value.RAM, serverDefaultConfig?.value?.RAM?.paramPricePattern || 0),
  NVME: getTotalPriceForParam(params.value.NVME, serverDefaultConfig?.value?.NVME?.paramPricePattern || 0),
}));

const isDiscountApplied = computed(() => serverDefaultConfig.value?.discount?.isApplied)

const totalPrice = computed(() => {
  return Object.keys(totalPriceParams.value).reduce((total, currentParam) => {
    return total + totalPriceParams.value[currentParam];
  }, 0);
});

const totalPriceDiscounted = computed(() => {
  if(isDiscountApplied.value) {
    return (totalPrice.value / 100) * (100 - serverDefaultConfig.value?.discount?.amount)
  }

  return totalPrice.value
});

const totalDiscount = computed(() => {
  return totalPrice.value - totalPriceDiscounted.value
});

const totalPriceWithoutVat = computed(() => {
  return totalPrice.value
});

const osList = ref([]);
const defaultOs = ref(null);

const osChoicesList = computed(() => {
  return osList.value.map(({ value, label }) => {
    return {
      value,
      label,
      selected: value === defaultOs.value,
    };
  });
});

const isReady = ref(false);

const setConfiguratorConfig = ({ opts, serverName, defaultStep = 1 }) => {
  server.value = opts.items[serverName];
  if (defaultStep === 1) {
    serverDefaultConfig.value = {
      type: opts.type,
      step: opts.step,
      discount: {
        isApplied: server.value?.['discount-configurator'] && server.value?.['discount-configurator'] > 0,
        amount: server.value?.['discount-configurator'],
        label: server.value?.['discount-configurator-label'],
        info: server.value?.['discount-configurator-info'],
      },
      ...server.value.pack_params
        .filter(param => param.values)
        .reduce((params, currentParam) => {
          const [minParamValue, maxParamValue] = currentParam.values[0].value
            .split('-')
            .map(value => parseInt(value));

          const paramPricePattern = currentParam.values[0].price_pattern;
          return {
            ...params,
            [currentParam.param_name]: {
              param: currentParam.param_name,
              minParamValue,
              maxParamValue,
              paramPricePattern,
            },
          };
        }, {}),
    };

    params.value.CPU = serverDefaultConfig.value.CPU.minParamValue
    params.value.RAM = serverDefaultConfig.value.RAM.minParamValue
    params.value.NVME = serverDefaultConfig.value.NVME.minParamValue

    return;
  }

  serverDefaultConfig.value = {
    type: opts.type,
    step: opts.step,
    ...server.value,
  };
};

const getLabelFromProductTypeOS = (productType) => {
  if (productType.includes('WIN')) {
    return 'Windows OS';
  }

  if (productType.includes('LIN')) {
    return 'Linux OS';
  }

  return 'Unknown OS';
};

const resetConfigurator = () => {
  osList.value = []
  defaultOs.value = null
  params.value.OS = props.defaultOS
  params.value.CPU = props.defaultCPU
  params.value.RAM = props.defaultRAM
  params.value.NVME = props.defaultNVME
}

const handleAddToCart = async ({ serverName }) => {
  isAddingToCart.value = true
  try {
    await $checkout.addToCart({
      item_name: `${serverName}.virtual`,
      item_pack: server.value?.['product-name'],
      item_op_custom_name: serverName,
      item_type: server.value?.['product-type'],
      item_period: server.value?.['product-period'],
      item_ram: params.value?.RAM,
      item_cpu: params.value?.CPU,
      item_nvme: params.value?.NVME,
    });
    dataLayer.addToCart({
      item_name: server.value?.['product-name'],
      item_category: 'server',
      item_id: `${server.value?.['product-name']}_${serverName}`,
    }, {
      period: server.value?.['product-period'],
      base_price: totalPrice.value,
      final_price: totalPriceDiscounted.value,
    })
    window.location.href = '/checkout?step=1'
  } catch (e) {
    console.error(e)
  } finally {
    isAddingToCart.value = false
    closeAllModals()
  }
}

const handleClickContinue = async () => {
  const isVuelidate = await $vParams.value.$validate()

  if(!isVuelidate) {
    return
  }
  if(serverDefaultConfig.value?.type === 'vms') {
    await handleAddToCart({ serverName: `${config.vmsNamePlaceholder}${Math.floor(Math.random() * 100) + 1}` })
    return
  }
  step.value = 2
}

const scrollModalToBottomAfterDelay = () => {
  isSummaryModalOpen.value = !isSummaryModalOpen.value
  if(!isSummaryModalOpen.value) return
  const timeout = setTimeout(() => {
    modalBody.value.scrollTop = modalBody.value.scrollHeight
    return clearTimeout(timeout)
  }, 300)
}

onMounted(async () => {
  window.webGlobe.wgEvent('openServerConfigurator', (eventOpts) => {
    closeAllModals()
    resetConfigurator()

    opts.value = parseOpts(eventOpts);
    step.value = opts.value.step;

    const defaultServerName = Object.keys(opts.value?.items)?.[0];

    if (step.value === 1) {
      setConfiguratorConfig({
        opts: opts.value,
        serverName: defaultServerName,
      });

      params.value.CPU = serverDefaultConfig.value.CPU.minParamValue
      params.value.RAM = serverDefaultConfig.value.RAM.minParamValue
      params.value.NVME = serverDefaultConfig.value.NVME.minParamValue

      if (Object.values(opts.value.items).length > 1) {
        defaultOs.value = getLabelFromProductTypeOS(defaultServerName);
        osList.value = Object.values(opts.value.items).map(server => {
          return {
            value: server['product-name'],
            label: getLabelFromProductTypeOS(server['product-name']),
          };
        });
      }
    }

    if (step.value === 2) {
      setConfiguratorConfig({
        opts: opts.value,
        serverName: defaultServerName,
        defaultStep: step.value,
      });

      params.value.CPU = parseInt(serverDefaultConfig.value.params.CPU);
      params.value.RAM = parseInt(serverDefaultConfig.value.params.RAM);
      params.value.NVME = parseInt(serverDefaultConfig.value.params.NVME);
    }

    isReady.value = true;

    openModal('serverConfiguratorModal');
  });
});
</script>

<template>
  <Teleport to="body">
     <div class="modal modal--ignore-custom-top-offset fade" id="serverConfiguratorModal" tabindex="-1" aria-labelledby="serverConfiguratorModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div ref="modalBody" class="modal-body">
            <Button variant="link" color="gray" size="medium" class="modal-close" data-bs-dismiss="modal" iconLeft='<svg xmlns="http://www.w3.org/2000/svg" fill="none"><path fill="#7D7F8A" fill-rule="evenodd" d="M21.828 6.175a1.167 1.167 0 0 1 0 1.65l-14 14a1.167 1.167 0 0 1-1.65-1.65l14-14a1.167 1.167 0 0 1 1.65 0Z" clip-rule="evenodd"/><path fill="#7D7F8A" fill-rule="evenodd" d="M6.178 6.175a1.167 1.167 0 0 1 1.65 0l14 14a1.167 1.167 0 1 1-1.65 1.65l-14-14a1.167 1.167 0 0 1 0-1.65Z" clip-rule="evenodd"/></svg>' />
            <div v-if="isReady"
              class="b-server-configurator"
              :class="{ 'b-server-configurator--step-2': step === 2 }"
            >
              <h2 class="b-server-configurator__title">
                <template v-if="step === 1">
                  {{ $t('serverConfiguration.title', { type: serverDefaultConfig.type.toUpperCase() }) }}
                </template>
                <template v-if="step === 2">
                  {{ $t('serverConfiguration.step2Title') }}
                </template>
              </h2>
              <div v-if="isReady && step === 1" class="b-server-configurator__form">
                <template v-if="osChoicesList.length">
                  <div class="b-server-configurator__form-controls b-server-configurator__form-controls--os mb-20 mb-md-30">
                    <div class="b-server-configurator__form-controls-title mb-5 mb-lg-10 ms-lg-30">
                      <div class="b-server-configurator__form-controls-title-icon">1</div>
                      <h3 class="b-server-configurator__form-controls-title-text">{{ $t('serverConfiguration.osInstructions') }}</h3>
                    </div>
                    <div
                      class="b-form__group b-server-configurator__form-controls-os"
                    >

                      <label class="b-form__label-input">{{ $t('serverConfiguration.osVariantTitle') }}</label>
                      <div class="b-form-select">
                       <NxtChoices
                          choiceType="os"
                          :modelValue="params.OS"
                          :default-selected="props.defaultOS"
                          :choicesList="osChoicesList"
                          @onChange="(value) => {
                            setConfiguratorConfig({ opts, serverName: value })
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <div class="b-server-configurator__form-controls b-server-configurator__form-controls--params">
                  <div class="b-server-configurator__form-controls-params">
                    <div class="b-server-configurator__form-controls-title b-server-configurator__form-controls-title--params">
                      <div class="b-server-configurator__form-controls-title-icon">{{ osChoicesList.length ? 2 : 1 }}</div>
                      <h3 class="b-server-configurator__form-controls-title-text">{{ $t('serverConfiguration.instructions') }}</h3>
                    </div>
                    <ul class="b-server-configurator__form-controls-params-list" id="serverParamsAccordion">
                      <ServerConfigurationItem
                        :id="'CPU'"
                        :label="$t('serverConfiguration.cpuTitle')"
                        :default="props.defaultCPU"
                        :min="serverDefaultConfig?.CPU?.minParamValue"
                        :max="serverDefaultConfig?.CPU?.maxParamValue"
                        :unit="'CPU'"
                        :is-collapsed="false"
                        @on-change="value => params.CPU = value"
                        :v="$vParams.CPU"
                        :price="totalPriceParams.CPU"
                        :model-value="params.CPU"
                      />
                      <ServerConfigurationItem
                        :label="$t('serverConfiguration.ramTitle')"
                        :id="'RAM'"
                        :default="props.defaultRAM"
                        :min="serverDefaultConfig?.RAM?.minParamValue"
                        :max="serverDefaultConfig?.RAM?.maxParamValue"
                        :unit="'GB'"
                        @on-change="value => params.RAM = value"
                        :v="$vParams.RAM"
                        :price="totalPriceParams.RAM"
                        :model-value="params.RAM"
                      />
                      <ServerConfigurationItem
                        :id="'NVME'"
                        :label="$t('serverConfiguration.ssdTitle')"
                        :default="props.defaultNVME"
                        :min="serverDefaultConfig?.NVME?.minParamValue"
                        :max="NVME_CUSTOM_MAX"
                        :unit="'GB'"
                        @on-change="value => params.NVME = value"
                        :v="$vParams.NVME"
                        :price="totalPriceParams.NVME"
                        :model-value="params.NVME"
                      />
                    </ul>
                  </div>
                </div>
                <div
                  class="b-server-configurator__form-actions"
                  :class="{ 'b-server-configurator__form-actions--discount-applied': serverDefaultConfig?.discount?.isApplied }"
                >
                  <a
                    @click="scrollModalToBottomAfterDelay"
                    class="b-server-configurator__form-actions-title collapsed"
                    data-bs-toggle="collapse"
                    href="#paramsListCollapse"
                    role="button"
                    aria-expanded="true"
                    aria-controls="paramsListCollapse"
                  >
                    <span>
                      {{ $t('serverConfiguration.summaryTitle', { type: serverDefaultConfig.type.toUpperCase() }) }}
                    </span>
                    <svg class="b-server-configurator__form-actions-title-chevron" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z" fill="#2C57DE"/>
                    </svg>
                  </a>
                  <div ref="paramsListCollapse" class="collapse" id="paramsListCollapse">
                    <ul class="b-server-configurator__form-actions-params-list">
                      <ServerConfiguratorPriceItem :param="$t('serverConfiguration.cpuCount', { count: params.CPU })" :price="totalPriceParams.CPU" />
                      <ServerConfiguratorPriceItem :param="$t('serverConfiguration.ramCount', { count: params.RAM })" :price="totalPriceParams.RAM" />
                      <ServerConfiguratorPriceItem :param="$t('serverConfiguration.ssdCount', { count: params.NVME })" :price="totalPriceParams.NVME" />
                    </ul>
                  </div>
                  <div class="b-server-configurator__form-actions-divider"></div>
                  <ServerConfiguratorTotalPriceItem
                    level="total-without-vat"
                    :text="$t('serverConfigurator.totalPerMonthWithoutVat')"
                    :price="totalPriceWithoutVat"
                  />
                  <ServerConfiguratorTotalPriceItem
                    v-if="serverDefaultConfig?.discount?.isApplied"
                    level="total-discount"
                    :text="
                      serverDefaultConfig?.discount?.label ||
                      $t('serverConfigurator.totalPerMonthDiscount', {
                        discount: getCustomRoundedNumber(serverDefaultConfig?.discount?.amount)
                      })
                    "
                    :price="totalDiscount"
                    :info="serverDefaultConfig?.discount?.info"
                  />
                  <ServerConfiguratorTotalPriceItem
                    level="total"
                    :text="$t('serverConfigurator.totalPerMonth')"
                    :price="totalPriceDiscounted"
                  />
                  <Button @click="handleClickContinue" :label="$t('continue')" color="orange" :loading="isAddingToCart" />
                </div>
              </div>
              <ServerConfiguratorNameValidation
                v-if="isReady && step == 2 && serverDefaultConfig.type !== 'vms'"
                  :server="{
                    ...server,
                    ...params
                  }"
                :can-go-back="serverDefaultConfig.step !== 2"
                @on-go-back="step = 1"
                @on-add-to-cart="serverName => handleAddToCart({ serverName })"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
